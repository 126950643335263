<template>
    <div class="pt-4 login-tabs-container">
        <div class="mb-4">
            <h3 class="mb-4">Change Password</h3>
            <p>You are required to change your password.</p>
        </div>
        <div class="pt-2">
            <!-- <vs-input
                name="oldPassword"
                icon-no-border
                type="password"
                icon="lock"
                label-placeholder="Old Password"
                v-model="oldPassword"
                size="large"
                class="w-100"/> -->
            <vs-input
                name="password1"
                icon-no-border
                type="password"
                icon="lock"
                label-placeholder="New Password"
                v-model="firstPassword"
                size="large"
                class="w-100"/>
            <vs-input
                name="password12"
                icon-no-border
                type="password"
                icon="lock"
                label-placeholder="Confirm Password"
                v-model="secondPassword"
                size="large"
                class="w-100 pt-4"/>
            <p class="text-center mt-4 text-danger" v-if="status === 'error'">{{message}} </p>
            <div class="d-flex">
                <vs-button :class="status.length > 0 ? 'mx-auto mt-2 pl-4 pr-4' : 'mx-auto mt-4 pl-4 pr-4'" class="" size="large" :disabled="!validateForm" @click="submit">Submit</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ChangePassword',
    data() {
        return {
            firstPassword: '',
            secondPassword: ''
        }
    },
    computed: {
        validateForm() {
            return this.firstPassword.length >= 6 && this.secondPassword === this.firstPassword;
        },
        ...mapState('auth', ['status', 'message'])
    },
    methods: {
        submit() {
            this.$vs.loading();
            let payload = {
                password: this.firstPassword,
                closeAnimation: this.$vs.loading.close,
                success: () => this.$router.push('/app')
            }
            this.changePassword(payload);
        },
        ...mapActions('auth', ['changePassword'])
    }
}
</script>